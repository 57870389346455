/*
 * Copyright © 2021-2023 Technology Matters
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see https://www.gnu.org/licenses/.
 */

.landscape-marker-popup .mapboxgl-popup-tip {
  border: none;
  width: 10px;
  height: 10px;
}

.landscape-marker-popup .mapboxgl-popup-content {
  border-color: #ff580d;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
  max-width: 300px;
}

.landscape-marker-popup .mapboxgl-popup-content p {
  margin: 1.3em 0;
}
